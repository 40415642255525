import React, { useContext, useEffect } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

// Previews
import { withPreview } from "gatsby-source-prismic";

// Context
import { PageType } from "../components/context/page-type";

// SEO
import { PageSEO } from "../components/seo/page-seo";

const Page = styled.div`
  padding: 50px 0 0 0;
  margin: 0 0 150px 0;

  & p {
    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  @media (max-width: 860px) {
    padding: 70px 0 0 0;
    margin: 0;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 30px;
`;

const MissionStatement = styled.div`
  grid-column: 1 / 9;
  order: 1;

  padding: 250px 0 85px 0;

  // display: grid;
  // grid-auto-flow: dense;
  // grid-gap: 60px;
  // grid-auto-rows: 4vw;

  @media (max-width: 1200px) {
    grid-column: 1 / 13;
    order: 2;

    // grid-auto-flow: unset;
    // grid-gap: unset;
    // grid-auto-rows: unset;

    padding: 50px 0 50px 0;
  }

  @media (max-width: 800px) {
    padding: 30px 0;
  }

  & .text-container {
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    grid-column: span 2;
    grid-row: span 5;

    // max-width: 705px;

    & h1 {
      font-size: calc(24px + (36 - 24) * ((100vw - 300px) / (1500 - 300)));
      line-height: 130%;

      // font-size: 32px;
      // line-height: 130%;

      @media (max-width: 1100px) {
        font-size: 24px;
      }

      @media (max-width: 700px) {
        & br {
          display: none;
        }
      }
    }
  }
`;

const ContactDetails = styled.div`
  grid-column: 9 / 13;
  order: 2;

  @media (max-width: 1200px) {
    grid-column: 1 / 13;
    order: 1;

    & .text-container {
      margin: 0 0 0 auto;
      width: fit-content;
    }
  }

  @media (max-width: 768px) {
    & .text-container {
      margin: 0;
      width: 100%;
    }
  }

  & a {
    position: relative;
  }
`;

const StudioText = styled.div`
  grid-column: 1 / 13;
  order: 3;

  & .text-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 30px;

    & a {
      border-bottom: 2px solid #000;
    }

    & .text-block-container {
      grid-column: 6 / 12;
      max-width: 675px;

      @media (max-width: 1500px) {
        grid-column: 6 / 13;
      }

      @media (max-width: 1700px) {
        grid-column: 5 / 13;
      }

      @media (max-width: 860px) {
        grid-column: 1 / 13;
      }
    }

    & .pull-out-quote {
      grid-column: 1 / 9;

      padding: 85px 0;

      & p {
        font-size: calc(24px + (36 - 24) * ((100vw - 300px) / (1500 - 300)));
        line-height: 130%;

        @media (max-width: 1100px) {
          font-size: 24px;
        }

        @media (max-width: 700px) {
          & br {
            display: none;
          }
        }
      }

      @media (max-width: 1200px) {
        grid-column: 1 / 13;

        padding: 50px 0;
      }
    }
  }
`;

const ImageContainer = styled.div`
  grid-column: ${(props) => props.gridColumns};

  margin: 150px 0;
  order: 4;

  @media (max-width: 700px) {
    grid-column: 1 / 13;
    margin: 25px 0;
  }
`;

const BiographiesContainer = styled.div`
  grid-column: 1 / 13;
  order: 5;

  & ol {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 30px;

    & li {
      grid-column: span 4;

      &:first-of-type {
        grid-column: 2 / 6;
      }

      & .text-container {
        max-width: 675px;

        & a {
          border-bottom: 2px solid #000;
        }
      }
    }
  }

  @media (max-width: 1500px) {
    & ol {
      & li {
        grid-column: span 5;

        &:first-of-type {
          grid-column: span 5;
        }
      }
    }
  }

  @media (max-width: 1700px) {
    & ol {
      & li {
        grid-column: span 6;

        &:first-of-type {
          grid-column: span 6;
        }
      }
    }
  }

  @media (max-width: 860px) {
    & ol {
      display: block;
      grid-template-columns: unset;
      grid-column-gap: unset;

      & li {
        grid-column: unset;

        & .text-container {
          & p:empty {
            display: none;
            margin: 0;
          }

          & br {
            display: none;
          }
        }
      }
    }
  }
`;

const About = ({ data }) => {
  const [pageType, setPageType] = useContext(PageType);

  useEffect(() => {
    setPageType(`about`);
  }, []);

  const biographies = data.prismicAbout.data.biographies.map(
    (biography, index) => (
      <li key={`single_about_biography_${index}`}>
        <div
          className="text-container"
          dangerouslySetInnerHTML={{
            __html: biography.biography.html,
          }}
        />
      </li>
    )
  );

  let imageWidth = 4;
  if (data.prismicAbout.data.width === "1 Column") {
    imageWidth = 4;
  } else if (data.prismicAbout.data.width === "2 Columns") {
    imageWidth = 8;
  } else if (data.prismicAbout.data.width === "3 Columns") {
    imageWidth = 12;
  }

  let imageStartPosition = 1;
  if (data.prismicAbout.data.image_position === "Left") {
    imageStartPosition = 1;
  } else if (data.prismicAbout.data.image_position === "Center") {
    if (imageWidth === 4) {
      imageStartPosition = 5;
    } else if (imageWidth === 8) {
      imageStartPosition = 3;
    } else {
      imageStartPosition = 1;
    }
  } else if (data.prismicAbout.data.image_position === "Right") {
    if (imageWidth === 4) {
      imageStartPosition = 9;
    } else if (imageWidth === 8) {
      imageStartPosition = 5;
    } else {
      imageStartPosition = 1;
    }
  }

  const content = data.prismicAbout.data.body.map((content, index) => {
    if (content.slice_type === "text") {
      return (
        <div className="text-container" key={`single_text_container_${index}`}>
          <div
            className="text-block-container inline-link"
            dangerouslySetInnerHTML={{
              __html: content.primary.text1.html,
            }}
          />
        </div>
      );
    }

    if (content.slice_type === "pull_out_quote") {
      return (
        <div className="text-container" key={`single_text_container_${index}`}>
          <div
            className="pull-out-quote inline-link"
            dangerouslySetInnerHTML={{
              __html: content.primary.pull_out_quote.html,
            }}
          />{" "}
        </div>
      );
    }
  });

  return (
    <>
      <PageSEO
        title={
          data.prismicAbout.data.seo_title !== null
            ? data.prismicAbout.data.seo_title
            : data.prismicAbout.data.title.text
        }
        image={
          data.prismicAbout.data.seo_image !== null
            ? data.prismicAbout.data.seo_image.url
            : null
        }
        description={data.prismicAbout.data.seo_description}
        url={`https://mcmullanstudio.com${data.prismicAbout.url}`}
      />

      <Page>
        <Grid>
          <MissionStatement>
            <div
              className="text-container"
              dangerouslySetInnerHTML={{
                __html: data.prismicAbout.data.mission_statement.html,
              }}
            />
          </MissionStatement>

          <ContactDetails>
            <div
              className="text-container inline-link"
              dangerouslySetInnerHTML={{
                __html: data.prismicAbout.data.contact_details.html,
              }}
            />
          </ContactDetails>

          <StudioText>{content}</StudioText>

          {data.prismicAbout.data.image.hasOwnProperty("fluid") &&
            data.prismicAbout.data.image.fluid !== null && (
              <ImageContainer
                gridColumns={`${imageStartPosition} / ${
                  imageStartPosition + imageWidth
                }`}
              >
                <img
                  srcSet={data.prismicAbout.data.image.fluid.srcSetWebp}
                  src={data.prismicAbout.data.image.fluid.srcWebp}
                  alt={data.prismicAbout.data.image.alt}
                  loading={`lazy`}
                />
              </ImageContainer>
            )}

          <BiographiesContainer>
            <ol>{biographies}</ol>
          </BiographiesContainer>
        </Grid>
      </Page>
    </>
  );
};

export default withPreview(About);

export const query = graphql`
  query About($uid: String!) {
    prismicAbout(uid: { eq: $uid }) {
      url
      data {
        seo_title
        seo_image {
          url
        }
        seo_description
        title {
          text
        }
        body {
          ... on PrismicAboutBodyText {
            id
            slice_type
            primary {
              text1 {
                html
              }
            }
          }
          ... on PrismicAboutBodyPullOutQuote {
            id
            slice_type
            primary {
              pull_out_quote {
                html
              }
            }
          }
        }
        mission_statement {
          html
        }
        contact_details {
          html
        }
        image {
          alt
          fluid {
            srcWebp
            srcSetWebp
          }
        }
        width
        image_position
        biographies {
          biography {
            html
          }
        }
      }
    }
  }
`;
